import { Flex, Heading, Stack } from '@chakra-ui/layout';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import EquipmentFeaturesGroupResource from 'api/equipment_feature_group';
import { wrapperStyles } from 'assets/css/commonStyles';
import { AxiosError } from 'axios';
import { CenterSpinner } from 'components/common/CenterSpinner';
import EquipmentFeaturesGroupForm from 'components/oms/equipment_features_group/EquipmentFeaturesGroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  DataWrapperWithSuccess,
  EquipmentFeatureGroupFormType,
  EquipmentFeaturesGroup,
} from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { mapEquipmentGroupFormData } from 'utils/equipmentMappers';

const EditEquipmentFeaturesGroup = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const equipmentFeaturesApi = new EquipmentFeaturesGroupResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<
    DataWrapperWithSuccess<EquipmentFeaturesGroup>
  >({
    queryKey: [`equipment-features-group${id}`],
    queryFn: async () => {
      const response = await equipmentFeaturesApi.get(id);
      return response.data;
    },
  });

  const { mutate, isLoading: isPending } = useMutation<
    unknown,
    AxiosError,
    FormData
  >({
    mutationFn: async (data) => {
      const response = await equipmentFeaturesApi.update(id, data);
      return response.data;
    },
    onSuccess: () => {
      toast({
        title: strings.successfully_updated_equipment_feature_detail,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries(['equipment-group', 'list']);
      queryClient.invalidateQueries([`equipment-features-group${id}`, 'list']);
      history.goBack();
    },
    onError: () => {
      toast({
        title: strings.failed_to_update_equipment_feature_detail,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const methods = useForm<EquipmentFeatureGroupFormType>();

  if (isLoading) {
    return <CenterSpinner />;
  }

  const onSubmit: SubmitHandler<EquipmentFeatureGroupFormType> = (data) => {
    const formData = mapEquipmentGroupFormData(data);
    formData.append('_method', 'PUT');
    mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.oms} | {data?.data?.name || ''}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.oms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routes.oms.equipmentFeatures.list}>
            {strings.equipment_features_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {data?.data?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.edit_equipment_feature_group}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              <EquipmentFeaturesGroupForm equipmentFeatureDetail={data?.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isPending}
                  isDisabled={isPending}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditEquipmentFeaturesGroup;
