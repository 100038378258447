import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Grid, GridItem } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Switch } from '@chakra-ui/switch';
import EquipmentFeaturesGroupResource from 'api/equipment_feature_group';
import CustomTabs from 'components/common/CustomTab';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { LANGUAGE_OPTIONS, TITLE_MAPPER } from 'constants/common';
import {
  DataWrapperSchema,
  EquipmentFeatures,
  EquipmentFeaturesFormType,
  EquipmentFeaturesGroup,
  LanguageAbbreviationType,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { fallbackGermanValue } from 'utils';

type Props = {
  equipmentFeatureDetail?: EquipmentFeatures;
};

const EquipmentFeaturesForm: React.FC<Props> = ({ equipmentFeatureDetail }) => {
  const equipmentFeatureAPI = new EquipmentFeaturesGroupResource();
  const equipmentTitles = new Map<LanguageAbbreviationType, string>();

  const equipmentFeatureList = useQuery<
    DataWrapperSchema<EquipmentFeaturesGroup[]>
  >({
    queryKey: ['equipment-group', 'list'],
    queryFn: async () => {
      const response = await equipmentFeatureAPI.list({
        page: 1,
        limit: 200,
      });
      return response.data;
    },
  });

  equipmentFeatureDetail?.translations.forEach((item) => {
    equipmentTitles.set(item.locale, item.name);
  });

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<EquipmentFeaturesFormType>();

  useEffect(() => {
    if (!equipmentFeatureDetail) return;
    setValue('status', equipmentFeatureDetail.status);
    setValue('special', equipmentFeatureDetail.special);
    if (!!equipmentFeatureDetail?.feature_group)
      setValue('feature_group_id', equipmentFeatureDetail.feature_group.id);
  }, [equipmentFeatureDetail, setValue]);

  useEffect(() => {
    const errs: string[] = [];
    if (errors.titles_de) {
      errs.push(`${strings.de}: ${errors.titles_de.message}`);
    }
  }, [
    errors,
    errors.titles_de,
    errors.titles_da,
    errors.titles_en,
    errors.titles_zh,
    errors.titles_pl,
  ]);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      e.currentTarget.name as keyof EquipmentFeaturesFormType,
      e.currentTarget.checked ? 1 : 0
    );
  };

  return (
    <form>
      <CustomTabs
        display="flex"
        flexDirection="column"
        gridGap="4"
        defaultIndex={0}>
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl
                    isInvalid={!!errors?.[TITLE_MAPPER[lang.value]]}
                    isRequired={lang.value === 'de'}>
                    <FormLabel>
                      {strings.name} ({strings[lang.value]})
                    </FormLabel>
                    <Input
                      type="text"
                      defaultValue={
                        equipmentTitles.get(lang.value) ||
                        fallbackGermanValue(
                          lang.value,
                          equipmentFeatureDetail?.name
                        )
                      }
                      placeholder={strings.name}
                      {...register(TITLE_MAPPER[lang.value], {
                        required: lang.value === 'de' && strings.title_required,
                      })}
                    />

                    {!!errors?.[TITLE_MAPPER?.[lang.value]]?.message && (
                      <FormErrorMessage>
                        {errors?.[TITLE_MAPPER?.[lang.value]]?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.equipment_features_group}</FormLabel>
              <Controller
                control={control}
                name="feature_group_id"
                render={({ field }) => (
                  <Select {...field} placeholder={strings.select}>
                    {equipmentFeatureList.data?.data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.special}</FormLabel>
              <Controller
                control={control}
                name="special"
                render={({ field: { name, value } }) => (
                  <Switch
                    colorScheme="primary"
                    name={name}
                    isChecked={!!value}
                    onChange={handleSwitchChange}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.status}</FormLabel>
              <Controller
                control={control}
                name="status"
                render={({ field: { name, value } }) => (
                  <Switch
                    colorScheme="primary"
                    name={name}
                    isChecked={!!value}
                    onChange={handleSwitchChange}
                  />
                )}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.icon} isRequired>
              <FormLabel>{strings.icon}</FormLabel>
              <ImageUploader
                fileKey="icon"
                accept="image/*"
                uploadedFiles={equipmentFeatureDetail?.icon?.url}
                title={strings.drag_image_or_click_to_select_image}
              />
              {!!errors.icon && (
                <FormErrorMessage>{errors.icon?.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        </Grid>
      </CustomTabs>
    </form>
  );
};

export default EquipmentFeaturesForm;
