import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Grid, GridItem } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import CustomTabs from 'components/common/CustomTab';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { LANGUAGE_OPTIONS, TITLE_MAPPER } from 'constants/common';
import {
  EquipmentFeaturesFormType,
  EquipmentFeaturesGroup,
  LanguageAbbreviationType,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { fallbackGermanValue } from 'utils';

type Props = {
  equipmentFeatureDetail?: EquipmentFeaturesGroup;
};

const EquipmentFeaturesGroupForm: React.FC<Props> = ({
  equipmentFeatureDetail,
}) => {
  const equipmentTitles = new Map<LanguageAbbreviationType, string>();

  equipmentFeatureDetail?.translations.forEach((item) => {
    equipmentTitles.set(item.locale, item.name);
  });

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<EquipmentFeaturesFormType>();

  useEffect(() => {
    if (!equipmentFeatureDetail) return;
    setValue('status', equipmentFeatureDetail.status);
  }, [equipmentFeatureDetail, setValue]);

  useEffect(() => {
    const errs: string[] = [];
    if (errors.titles_de) {
      errs.push(`${strings.de}: ${errors.titles_de.message}`);
    }
  }, [
    errors,
    errors.titles_de,
    errors.titles_da,
    errors.titles_en,
    errors.titles_zh,
    errors.titles_pl,
  ]);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      e.currentTarget.name as keyof EquipmentFeaturesFormType,
      e.currentTarget.checked ? 1 : 0
    );
  };

  return (
    <form>
      <CustomTabs
        display="flex"
        flexDirection="column"
        gridGap="4"
        defaultIndex={0}>
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl
                    isInvalid={!!errors?.[TITLE_MAPPER[lang.value]]}
                    isRequired={lang.value === 'de'}>
                    <FormLabel>
                      {strings.name} ({strings[lang.value]})
                    </FormLabel>
                    <Input
                      type="text"
                      defaultValue={
                        equipmentTitles.get(lang.value) ||
                        fallbackGermanValue(
                          lang.value,
                          equipmentFeatureDetail?.name
                        )
                      }
                      placeholder={strings.name}
                      {...register(TITLE_MAPPER[lang.value], {
                        required: lang.value === 'de' && strings.title_required,
                      })}
                    />

                    {!!errors?.[TITLE_MAPPER?.[lang.value]]?.message && (
                      <FormErrorMessage>
                        {errors?.[TITLE_MAPPER?.[lang.value]]?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.status}</FormLabel>
              <Controller
                control={control}
                name="status"
                render={({ field: { name, value } }) => (
                  <Switch
                    colorScheme="primary"
                    name={name}
                    isChecked={!!value}
                    onChange={handleSwitchChange}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.icon} isRequired>
              <FormLabel>{strings.icon}</FormLabel>
              <ImageUploader
                fileKey="icon"
                accept="image/png, image/svg"
                uploadedFiles={equipmentFeatureDetail?.icon?.url}
                title={strings.drag_image_or_click_to_select_image}
              />
              {!!errors.icon && (
                <FormErrorMessage>{errors.icon?.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        </Grid>
      </CustomTabs>
    </form>
  );
};

export default EquipmentFeaturesGroupForm;
