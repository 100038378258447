import { IconButton } from '@chakra-ui/button';
import { Stack, Text } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import { Td, Tr } from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import EquipmentFeatureResource from 'api/equipment_feature';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { EquipmentFeatures, EquipmentFeaturesFormType } from 'constants/schema';
import React from 'react';
import { BiEdit, BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
type Props = {
  equipmentFeature: EquipmentFeatures;
  startingSN: number;
  queryKey?: readonly ['equipment', 'list', string | undefined, number, number];
};

const EquipmentFeaturesListItem: React.FC<Props> = ({
  equipmentFeature,
  startingSN,
  queryKey,
}) => {
  const toast = useToast();
  const equipmentFeatureAPI = new EquipmentFeatureResource();
  const queryClient = useQueryClient();

  const { mutate: updateMutation, isLoading: isUpdateMutationLoading } =
    useMutation({
      mutationFn: (payload: Partial<EquipmentFeaturesFormType>) =>
        equipmentFeatureAPI.update(equipmentFeature.id, {
          ...payload,
          _method: 'PUT',
        }),
      onSuccess: () => {
        toast({
          status: 'success',
          title: strings.successfully_updated_equipment_feature_detail,
        });
        queryClient.invalidateQueries({ queryKey: queryKey });
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.failed_to_update_equipment_feature_detail,
        });
      },
    });

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const data = { [name]: checked ? 1 : 0 };
    updateMutation(data);
  };

  return (
    <Tr>
      <Td>{startingSN}</Td>
      <Td>
        <Link
          to={routes.oms.equipmentFeatures.details.replace(
            ':id',
            equipmentFeature.id.toString()
          )}>
          <Stack direction="row" alignItems="center">
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {equipmentFeature.name}
            </Text>
          </Stack>
        </Link>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="special"
            colorScheme="green"
            isChecked={!!equipmentFeature.special}
            isDisabled={isUpdateMutationLoading}
            onChange={onStatusChange}
          />
        </Stack>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="status"
            colorScheme="green"
            isChecked={!!equipmentFeature.status}
            isDisabled={isUpdateMutationLoading}
            onChange={onStatusChange}
          />
        </Stack>
      </Td>
      <Td>
        <Link
          to={routes.oms.equipmentFeatures.details.replace(
            ':id',
            equipmentFeature.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </Link>
        <Link
          to={routes.oms.equipmentFeatures.edit.replace(
            ':id',
            equipmentFeature.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </Link>
      </Td>
    </Tr>
  );
};

export default EquipmentFeaturesListItem;
