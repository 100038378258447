import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ImageComponent from 'components/ImageComponent';
import PartnerGoodiesList from 'components/third_party_partner/PartnerGoodiesList';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const ThirdPartyPartnerDetails: React.FC = () => {
  const { search } = useLocation();
  const { id: thirdPartyPartnerId } = useParams<{ id: string }>();

  let thirdPartyPartnerAPI = new ThirdPartyPartnerResource();

  const { data: thirdPartyPartnerDetail, isLoading } = useQuery({
    queryKey: ['third-party-partner-details-page', thirdPartyPartnerId],
    queryFn: async () => {
      const response = await thirdPartyPartnerAPI.get(thirdPartyPartnerId);
      return response.data.data;
    },
    enabled: !!thirdPartyPartnerId,
  });

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.membership} | &nbsp;
            {strings.detail_third_party_partner}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
              {strings.membership}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.thirdPartyPartner.list + search}>
              {strings.third_party_partner}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.detail_third_party_partner}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.third_party_partner} {thirdPartyPartnerDetail?.name}
          </Heading>
          <RouterLink
            to={routes.thirdPartyPartner.thirdPartyPartner.edit.replace(
              ':id',
              thirdPartyPartnerId.toString()
            )}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.edit_third_party_partner}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            templateColumns={['repeat(1,1fr)', 'repeat(2, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel>{strings.name_third_party_partner}</FormLabel>
                <Box>{thirdPartyPartnerDetail?.name}</Box>
              </FormControl>
            </GridItem>
            <GridItem rowSpan={[1, 2]}>
              <FormControl>
                <FormLabel>{strings.description}</FormLabel>
                <Box>{thirdPartyPartnerDetail?.description}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.brief_description}</FormLabel>
                <Box>{thirdPartyPartnerDetail?.brief_description}</Box>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl></FormControl>
              <FormLabel>{strings.active}</FormLabel>
              <Box>
                {thirdPartyPartnerDetail?.is_active ? strings.yes : strings.no}
              </Box>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.icon}</FormLabel>
                <Box width="160px">
                  <ImageComponent
                    src={thirdPartyPartnerDetail?.icon}
                    alt={thirdPartyPartnerDetail?.icon}
                    width="500"
                    height="150"
                  />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <PartnerGoodiesList
          data={thirdPartyPartnerDetail?.partner_goodies}
          thirdPartyPartnerId={thirdPartyPartnerId}
        />
      </Stack>
    </>
  );
};

export default ThirdPartyPartnerDetails;
