import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/layout';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import EquipmentFeaturesGroupResource from 'api/equipment_feature_group';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTabs from 'components/common/CustomTab';
import ImageComponent from 'components/ImageComponent';
import { strings } from 'config/localization';
import { LANGUAGE_OPTIONS } from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapperWithSuccess,
  EquipmentFeaturesGroup,
  LanguageAbbreviationType,
} from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { fallbackGermanValue } from 'utils';

const EquipmentFeaturesGroupDetails = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const equipmentFeaturesApi = new EquipmentFeaturesGroupResource();

  const { data, isLoading, isError } = useQuery<
    DataWrapperWithSuccess<EquipmentFeaturesGroup>
  >({
    queryKey: [`equipment-features-group${id}`],
    queryFn: async () => {
      const response = await equipmentFeaturesApi.get(id);
      return response.data;
    },
  });

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (!data || isError) {
    return <Text>{strings.error_boundary_heading_text}</Text>;
  }

  const equipmentName = new Map<LanguageAbbreviationType, string>();
  data.data.translations.forEach((item) => {
    equipmentName.set(item.locale, item.name);
  });

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.oms} | {data?.data?.name || ''}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.oms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routes.oms.equipmentFeaturesGroup.list}>
            {strings.equipment_features_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {data?.data?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.equipment_features_group_detail}
        </Heading>
        <Stack direction="row" spacing="6">
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              history.push(
                routes.oms.equipmentFeaturesGroup.edit.replace(
                  ':id',
                  data.data.id.toString()
                )
              )
            }>
            {strings.edit_equipment_feature}
          </Button>
        </Stack>
      </Flex>
      <CustomTabs
        display="flex"
        flexDirection="column"
        gridGap="4"
        sx={wrapperStyles}
        defaultIndex={0}>
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
          w="100%">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      {strings.name} ({strings[lang.value]})
                    </FormLabel>
                    <Box sx={infoStyles}>
                      {equipmentName.get(lang.value) ||
                        fallbackGermanValue(lang.value, data.data.name) ||
                        '-'}
                    </Box>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>{strings.status}</FormLabel>
              <Text>{data?.data?.status ? strings.yes : strings.no}</Text>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.cover_image}</FormLabel>
              {!!data.data.icon ? (
                <Box sx={infoStyles}>
                  <ImageComponent
                    bg="gray.100"
                    shadow="box"
                    rounded="sm"
                    p="1"
                    boxSize="200px"
                    objectFit="cover"
                    src={data.data.icon.url}
                    alt={strings.icon}
                  />
                </Box>
              ) : (
                '-'
              )}
            </FormControl>
          </GridItem>
        </Grid>
      </CustomTabs>
    </Stack>
  );
};

export default EquipmentFeaturesGroupDetails;
