import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import CustomTabs from 'components/common/CustomTab';
import ImageComponent from 'components/ImageComponent';
import { strings } from 'config/localization';
import { LANGUAGE_OPTIONS } from 'constants/common';
import routes from 'constants/routes';
import { LanguageAbbreviationType, ServiceSchema } from 'constants/schema';
import React from 'react';
import { fallbackGermanValue } from 'utils';
import history from 'utils/history';
import ServiceSchemeType from './ServiceSchemType';

interface Props {
  serviceData: ServiceSchema;
  search: string;
}

const ServiceInfo: React.FC<Props> = (props) => {
  const { serviceData, search } = props;

  const titles = new Map<LanguageAbbreviationType, string>();
  serviceData.titles.forEach((item) => {
    titles.set(item.language, item.title);
  });

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.service_details}
        </Heading>
        <Stack direction="row" spacing="6">
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              history.push(
                routes.sms.services.edit.replace(
                  ':id',
                  serviceData.id.toString()
                ) + search
              )
            }>
            {strings.edit_service}
          </Button>
        </Stack>
      </Flex>

      <CustomTabs
        display="flex"
        flexDirection="column"
        gridGap="4"
        defaultIndex={0}
        sx={wrapperStyles}>
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      {strings.service_name} ({strings[lang.value]})
                    </FormLabel>
                    <Box sx={infoStyles}>
                      {titles.get(lang.value) ||
                        fallbackGermanValue(lang.value, serviceData.name) ||
                        '-'}
                    </Box>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.product_code}</FormLabel>
              <Box sx={infoStyles}>{serviceData?.product_code || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.service_group}</FormLabel>
              <Box sx={infoStyles}>
                {serviceData?.service_group?.name || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.v_office_service}</FormLabel>
              <Box sx={infoStyles}>{serviceData?.v_service?.name || '-'}</Box>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
              <Box sx={infoStyles}>{serviceData?.description || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.cover_image}</FormLabel>
              <Box sx={infoStyles}>
                <ImageComponent
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={serviceData?.cover}
                  alt={strings.icon}
                />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.price_scheme_type}
              </FormLabel>
              <Box sx={infoStyles}>
                {strings.getString(
                  serviceData?.price_scheme_type.toLowerCase()
                ) || '-'}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
        {serviceData?.prices.length > 0 && (
          <ServiceSchemeType
            schemeData={serviceData?.prices}
            schemeType={serviceData?.price_scheme_type}
          />
        )}
      </CustomTabs>
    </>
  );
};
export default ServiceInfo;
