import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Heading,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import EquipmentFeatureResource from 'api/equipment_feature';
import { wrapperStyles } from 'assets/css/commonStyles';
import DebounceInput from 'components/common/DebounceInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import EquipmentFeaturesListItem from 'components/oms/equipment_features/EquipmentFeaturesListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { DataWrapperSchema, EquipmentFeatures } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData, getStartingSerialNumber } from 'utils';

type FilterParams = {
  currentPage: number;
  pageSize: number;
  name?: string;
};

type Props = {
  isLoading: boolean;
  filterParams: FilterParams;
  data: DataWrapperSchema<EquipmentFeatures[]> | undefined;
  startingSN: number;
  queryKey?: readonly ['equipment', 'list', string | undefined, number, number];
};

const RenderContent: React.FC<Props> = ({
  isLoading,
  filterParams,
  data,
  startingSN,
  queryKey,
}) => {
  if (isLoading) {
    return <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />;
  }
  if (!data || data.data.length === 0) {
    return (
      <Tr>
        <Td colSpan={5} textAlign="center">
          {strings.no_records_found}
        </Td>
      </Tr>
    );
  }
  return (
    <>
      {data.data.map((item, index) => (
        <EquipmentFeaturesListItem
          key={item.id}
          startingSN={startingSN + index}
          equipmentFeature={item}
          queryKey={queryKey}
        />
      ))}
    </>
  );
};

const EquipmentFeaturesList = () => {
  const equipmentFeatureAPI = new EquipmentFeatureResource();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage:
      Number(searchParams.get('currentPage')) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE,
    name: searchParams.get('name') || '',
  });

  const queryKey = useMemo(() => {
    return [
      'equipment',
      'list',
      filterParams.name,
      filterParams.currentPage,
      filterParams.pageSize,
    ] as const;
  }, [filterParams]);

  const equipmentFeatureList = useQuery<DataWrapperSchema<EquipmentFeatures[]>>(
    {
      queryKey: queryKey,
      queryFn: async () => {
        const searchParams = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
          name: filterParams.name,
        } as const;
        const cleanParams = cleanData(searchParams);
        const response = await equipmentFeatureAPI.list(cleanParams);
        return response.data;
      },
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  useEffect(() => {
    const searchUrl = changeURL(filterParams);
    history.push(`?${searchUrl}`);
  }, [filterParams, history]);

  const handleReset = () => {
    setFilterParams({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.oms} | {strings.equipment_features}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.oms.equipmentFeatures.list}>
            {strings.oms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.equipment_features}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="md" textTransform="capitalize">
        {strings.equipment_features}
      </Heading>
      <Stack sx={wrapperStyles}>
        <Stack
          direction={['column', 'column', 'row']}
          spacing="4"
          align={['start', 'start', 'end']}>
          <FormControl>
            <FormLabel>{strings.name}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearch />}
                color="gray.400"
              />
              <DebounceInput
                name="name"
                placeholder={strings.name}
                initialValue={searchParams.get('name') ?? ''}
                setFilterParams={setFilterParams}
              />
            </InputGroup>
          </FormControl>
          <Button
            colorScheme="primary"
            type="button"
            variant="outline"
            onClick={handleReset}>
            {strings.reset_filter}
          </Button>
        </Stack>
      </Stack>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.special}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <RenderContent
                isLoading={equipmentFeatureList.isLoading}
                data={equipmentFeatureList.data}
                filterParams={filterParams}
                startingSN={startingSN}
                queryKey={queryKey}
              />
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={equipmentFeatureList}
      />
    </Stack>
  );
};

export default EquipmentFeaturesList;
