/**
 * Custom Hook to check permission before navigating to a particular route
 *
 * Shows toast with insufficient permissions message if permission is not sufficient
 * Redirects to the provided route if permission is sufficient.
 *
 * Use `checkPermission` handler function on `onClick` of action item
 * which leads to a different route
 *
 */

import { useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkPermissions, checkSomePermissions } from 'utils/listInfo';

const useCheckPermission = (
  allowedPermissions: string[],
  route?: string,
  callbackFunc?: () => void,
  check?: 'some'
) => {
  const history = useHistory();
  const toast = useToast();
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const checkPermission = () => {
    let isPermitted: boolean;
    if (check === 'some') {
      isPermitted = checkSomePermissions(userPermissions, allowedPermissions);
    } else {
      isPermitted = checkPermissions(userPermissions, allowedPermissions);
    }
    if (!isPermitted) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (route !== undefined) {
      history.push(route);
    }
    callbackFunc && callbackFunc();
  };
  return checkPermission;
};

export default useCheckPermission;
