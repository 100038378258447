import { Image, ImageProps } from '@chakra-ui/react';
import React from 'react';

const APP_URL = process.env.REACT_APP_URL_FOR_IMAGEPROXY;
const IMAGE_PROXY_URL = process.env.REACT_APP_IMAGEPROXY_URL;

const BLUR_URL =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4QBYRXhpZgAATU0AKgAAAAgAAgESAAMAAAABAAEAAIdpAAQAAAABAAAAJgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABKKADAAQAAAABAAABAAAAAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAQABADASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/9oACAEBAAA/EP8A/9oADAMBAAIRAxEAPwD/2Q';

const getTransformation = (src: string, width: number) => {
  const srcWithDomain =
    src.startsWith('http') || src.startsWith('https')
      ? src
      : `${APP_URL}${src}`;

  return `/insecure/resize:auto:${width}:/plain/${srcWithDomain}@webp`;
};

const imageLoader = (src: string, width: number) => {
  return `${IMAGE_PROXY_URL}${getTransformation(src, width)}`;
};

const ImageComponent = ({ src, width = '1400', ...rest }: ImageProps) => {
  return (
    <Image
      loading="lazy"
      src={imageLoader(src ?? '', width as number)}
      fallbackSrc={BLUR_URL}
      width="100%"
      onError={(e) => console.error('Image failed to load:', e)}
      {...rest}
    />
  );
};

export default ImageComponent;
