import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import ReservationResource from 'api/reservation';
import { selectColorScheme, wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { DataWrapperSchema, ReservationListSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch, BiShow } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
  status: string;
}

const ReservationList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    status: searchValues.status ?? '',
  });

  const ReservationAPI = new ReservationResource();

  const reservationList = useQuery<DataWrapperSchema<ReservationListSchema[]>>(
    [
      'reservation-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        keyword: filterParams.keyword,
      },
    ],
    () => {
      let { status, keyword } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (status) queryParams.status = status;

      if (keyword) queryParams.keyword = keyword;

      return ReservationAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      status: '',
    }));
    history.push(routes.bms.reservation.list);
  }

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.bms} | {strings.all_reservations}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.bms.reservation.list}>
            {strings.all_reservations}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box>
        <Heading size="md" textTransform="capitalize">
          {strings.reservations}
        </Heading>
      </Box>
      <Stack sx={wrapperStyles}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.search}</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BiSearch />}
                  color="gray.400"
                />
                <Input
                  type="text"
                  name="keyword"
                  value={filterParams.keyword}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.status}</FormLabel>
              <Select
                name="status"
                placeholder={`${strings.select} ${strings.status}`}
                onChange={handleInputChange}
                value={filterParams.status}>
                <option value="pending">{strings.pending}</option>
                <option value="confirmed">{strings.confirmed}</option>
              </Select>
            </FormControl>

            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.object_name}</Th>
                <Th>{strings.booking_date}</Th>
                <Th>{strings.customer}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.date}</Th>
                <Th>{strings.actions}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reservationList?.data?.data?.map(
                (
                  {
                    id,
                    booking_number,
                    status,
                    booking_period,
                    apartment,
                    customer = {},
                    updated_at,
                  },
                  index: number
                ) => (
                  <Tr key={id}>
                    <Td>{startingSN + index}</Td>
                    <Td
                      fontWeight="medium"
                      color="gray.500"
                      _hover={{ color: 'gray.600' }}>
                      <RouterLink
                        to={
                          routes.bms.reservation.details.replace(
                            ':id',
                            id.toString()
                          ) + search
                        }>
                        {booking_number ?? '-'}
                      </RouterLink>
                    </Td>
                    <Td>{apartment?.apartment_name ?? '-'}</Td>
                    <Td>{booking_period ?? '-'}</Td>
                    <Td>
                      {!!customer?.forename && !!customer?.surname
                        ? `${customer?.forename} ${customer?.surname}`
                        : '-'}
                    </Td>

                    <Td>
                      <Text
                        color={selectColorScheme(status)}
                        textTransform="uppercase"
                        fontWeight="medium"
                        fontSize="sm">
                        {strings.getString(status)}
                      </Text>
                    </Td>
                    <Td>{DateFormat(updated_at)}</Td>
                    <Td>
                      <RouterLink
                        to={
                          routes.bms.reservation.details.replace(
                            ':id',
                            id.toString()
                          ) + search
                        }>
                        <Tooltip hasArrow label={strings.view}>
                          <IconButton
                            icon={<BiShow />}
                            variant="link"
                            aria-label={strings.view}
                            color="green.300"
                            minW="6"
                          />
                        </Tooltip>
                      </RouterLink>
                    </Td>
                  </Tr>
                )
              )}
              {reservationList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={reservationList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default ReservationList;
