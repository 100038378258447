import { Box, Flex, Heading, Stack, Tooltip } from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_REFETCH_TIME } from 'constants/common';
import {
  DataWrapperSchema,
  TicketInfoSchema,
  TicketStatusSchema,
} from 'constants/schema';
import React, { useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import TaskListItem from './TaskListItem';

interface Props {
  ticketStatus: TicketStatusSchema;
  getQueryParams: (status: TicketStatusSchema) => {};
}

type MemorizedProps = {
  ticketStatus: TicketStatusSchema;
  isLoading: boolean;
  initialRender: boolean;
  handleLoadMore: () => void;
  ticketList: TicketInfoSchema[];
  hasMorePage: boolean;
  totalCount: number;
};

const getTicketStatusName = (status: string) => {
  switch (status) {
    case 'open':
      return strings.open;
    case 'inProgress':
      return strings.inprogress;
    case 'done':
      return strings.done;
    case 'closed':
      return strings.closed;
    default:
      return '';
  }
};

const MemoizedInnerList = React.memo(function InnerList(props: MemorizedProps) {
  const {
    ticketStatus,
    handleLoadMore,
    isLoading,
    initialRender,
    ticketList,
    hasMorePage,
    totalCount,
  } = props;

  const { search } = useLocation();

  return (
    <Stack bg="white">
      <Flex justifyContent="space-between" pt="3" pb="1" px="4">
        <Heading size="xs">{getTicketStatusName(ticketStatus)}</Heading>
        <Tooltip hasArrow label={`${totalCount} ${strings.tickets}`}>
          <Heading size="xs" color="gray.400">
            {totalCount}
          </Heading>
        </Tooltip>
      </Flex>
      <Box borderBottom="1px" borderColor="blue.400" />
      <Box h={['85vh', '80vh', '75vh', '100vh']}>
        {isLoading && initialRender && <TicketSkeletonLoader rows={3} />}
        <PerfectScrollbar
          className="show-scroll"
          id={`scrollable-ticket-parent-target-${ticketStatus}`}>
          <InfiniteScroll
            dataLength={ticketList?.length}
            next={handleLoadMore}
            hasMore={hasMorePage}
            loader={<TicketSkeletonLoader rows={3} />}
            scrollableTarget={`scrollable-ticket-parent-target-${ticketStatus}`}>
            {ticketList?.map((task, index: number) => (
              <Draggable
                key={task.id}
                draggableId={task.id.toString()}
                index={index}>
                {(provided) => (
                  <Box
                    bg="white"
                    py="2"
                    px="4"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <TaskListItem
                      ticketId={task.id}
                      title={task.title}
                      appointment_date={task?.appointment_date}
                      object={task.apartment?.name}
                      assignedUserStatus={task.assigned_user?.status}
                      assignedUserName={task.assigned_user?.name}
                      external_company={task.external_company}
                      recursive={task.recursive_interval}
                      billable={task.is_billable}
                      priority={task.priority}
                      avatar={task.assigned_user?.image_url}
                      dueDate={task.due_date}
                      search={search}
                      personInCharge={task?.apartment?.responsible_person}
                      substitutePersonInCharge={
                        task?.apartment?.substitute_responsible_person
                      }
                    />
                  </Box>
                )}
              </Draggable>
            ))}
          </InfiniteScroll>
        </PerfectScrollbar>
      </Box>
    </Stack>
  );
});

const TaskBoard = (props: Props) => {
  const { ticketStatus, getQueryParams } = props;

  const ticketApi = new TicketResource();

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery<DataWrapperSchema<TicketInfoSchema>>({
      queryKey: ['ticket', 'board', ticketStatus, getQueryParams(ticketStatus)],
      queryFn: async ({ pageParam = 1 }) => {
        const query = { ...getQueryParams(ticketStatus), page: pageParam };
        const response = await ticketApi.list(query);
        return response.data;
      },
      getNextPageParam: (lastPage) => {
        if (!lastPage) return null;
        return lastPage.meta.current_page < lastPage.meta.last_page
          ? lastPage.meta.current_page + 1
          : null;
      },
      cacheTime: DEFAULT_REFETCH_TIME,
      refetchInterval: DEFAULT_REFETCH_TIME,
      refetchOnWindowFocus: false,
    });

  const flatList = useMemo(() => {
    return data?.pages?.length ? data.pages.flatMap((item) => item?.data) : [];
  }, [data]);

  const totalCount = useMemo(() => {
    return data?.pages?.[0]?.meta?.total || 0;
  }, [data]);

  return (
    <Box w="25%" minWidth="300px">
      <Droppable droppableId={ticketStatus} key={ticketStatus}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <MemoizedInnerList
              ticketStatus={ticketStatus}
              handleLoadMore={fetchNextPage}
              isLoading={isFetching}
              initialRender={isLoading}
              hasMorePage={!!hasNextPage}
              ticketList={flatList}
              totalCount={totalCount}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};

export default TaskBoard;
