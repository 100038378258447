import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MeerSyltResource from 'api/sms_meer_sylt';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTabs from 'components/common/CustomTab';
import ImageComponent from 'components/ImageComponent';
import { strings } from 'config/localization';
import {
  LANGUAGE_OPTIONS,
  TOAST_DURATION,
  TOAST_STATUS,
} from 'constants/common';
import routes from 'constants/routes';
import {
  LanguageAbbreviationType,
  MeerSyltDescriptionItem,
  MeerSyltTitleItem,
} from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { fallbackGermanValue, truncateString } from 'utils';

const MeerSyltDetails: React.FC = () => {
  const { id: meerSyltId } = useParams<{ id: string }>();

  let meerSyltAPI = new MeerSyltResource();
  const toast = useToast();

  const { data: meerSyltDetail, isLoading } = useQuery(
    ['meer-styl-details-page', meerSyltId],
    () => {
      if (!meerSyltId) return null; // { data: null };
      return meerSyltAPI
        .get(meerSyltId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: TOAST_STATUS.ERROR,
            duration: TOAST_DURATION.LONG,
            isClosable: true,
          });
        });
    }
  );

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  const titles = new Map<LanguageAbbreviationType, string>();
  const descriptions = new Map<LanguageAbbreviationType, string>();

  meerSyltDetail?.titles.forEach((item: MeerSyltTitleItem) => {
    titles.set(item.language, item.title);
  });

  meerSyltDetail?.descriptions.forEach((item: MeerSyltDescriptionItem) => {
    descriptions.set(item.language, item.description);
  });

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.meer_sylt} | &nbsp;
            {meerSyltDetail?.title}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.sms.meerSylt.list}>
              {strings.meer_sylt}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.meer_sylt_details}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {meerSyltDetail?.title}
          </Heading>
          <RouterLink
            to={routes.sms.meerSylt.edit.replace(':id', meerSyltId.toString())}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.edit_meer_sylt}
            </Button>
          </RouterLink>
        </Flex>
        <CustomTabs
          display="flex"
          flexDirection="column"
          gridGap="4"
          defaultIndex={0}
          sx={wrapperStyles}>
          <CustomTabs.TabList>
            {LANGUAGE_OPTIONS.map((lang) => (
              <CustomTabs.Tab key={lang.value}>
                {strings[lang.value]}
              </CustomTabs.Tab>
            ))}
          </CustomTabs.TabList>
          <Grid gap="4" templateColumns={['repeat(4, 1fr)']} w="100%">
            <GridItem colSpan={{ base: 4, sm: 2, md: 2, lg: 2, xl: 2 }}>
              <CustomTabs.TabPanels>
                {LANGUAGE_OPTIONS.map((lang) => (
                  <CustomTabs.TabPanel key={lang.value}>
                    <FormControl>
                      <FormLabel sx={labelStyles}>
                        {strings.title} ({strings[lang.value]})
                      </FormLabel>
                      <Box sx={infoStyles}>
                        {titles.get(lang.value) ||
                          fallbackGermanValue(
                            lang.value,
                            meerSyltDetail.title
                          ) ||
                          '-'}
                      </Box>
                    </FormControl>
                  </CustomTabs.TabPanel>
                ))}
              </CustomTabs.TabPanels>
            </GridItem>

            <GridItem colSpan={{ base: 4, sm: 2, md: 2, lg: 2, xl: 2 }}>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.link}</FormLabel>
                <Box sx={infoStyles}>
                  <a
                    href={meerSyltDetail?.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'blue',
                    }}>
                    {meerSyltDetail?.link
                      ? truncateString(meerSyltDetail?.link, 150)
                      : '-'}
                  </a>
                </Box>
              </FormControl>
            </GridItem>

            <GridItem colSpan={4}>
              <CustomTabs.TabPanels>
                {LANGUAGE_OPTIONS.map((lang) => (
                  <CustomTabs.TabPanel key={lang.value}>
                    <FormControl>
                      <FormLabel sx={labelStyles}>
                        {strings.text} ({strings[lang.value]})
                      </FormLabel>
                      <Box sx={infoStyles}>
                        {descriptions.get(lang.value) ||
                          fallbackGermanValue(
                            lang.value,
                            meerSyltDetail.text
                          ) ||
                          '-'}
                      </Box>
                    </FormControl>
                  </CustomTabs.TabPanel>
                ))}
              </CustomTabs.TabPanels>
            </GridItem>

            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.image}</FormLabel>
                <Box sx={infoStyles} width="200px">
                  <ImageComponent
                    src={meerSyltDetail?.image}
                    alt={meerSyltDetail?.image}
                    width="500"
                    height="180"
                    objectFit="contain"
                  />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </CustomTabs>
      </Stack>
    </>
  );
};

export default MeerSyltDetails;
