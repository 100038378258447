import { Flex, Heading, Stack } from '@chakra-ui/layout';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import EquipmentFeaturesGroupResource from 'api/equipment_feature_group';
import { wrapperStyles } from 'assets/css/commonStyles';
import { AxiosError } from 'axios';
import EquipmentFeaturesGroupForm from 'components/oms/equipment_features_group/EquipmentFeaturesGroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { EquipmentFeatureGroupFormType } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { mapEquipmentGroupFormData } from 'utils/equipmentMappers';

const AddEquipmentFeaturesGroup = () => {
  const history = useHistory();
  const equipmentFeaturesApi = new EquipmentFeaturesGroupResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<unknown, AxiosError, FormData>({
    mutationFn: async (data) => {
      const response = await equipmentFeaturesApi.store(data);
      return response.data;
    },
    onSuccess: () => {
      toast({
        title: strings.successfully_updated_equipment_feature_detail,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries(['equipment-group', 'list']);
      history.goBack();
    },
    onError: () => {
      toast({
        title: strings.failed_to_update_equipment_feature_detail,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const methods = useForm<EquipmentFeatureGroupFormType>();

  const onSubmit: SubmitHandler<EquipmentFeatureGroupFormType> = (data) => {
    if (!data.icon) {
      methods.setError('icon', {
        type: 'validate',
        message: strings.required_icon,
      });
      return;
    }
    const formData = mapEquipmentGroupFormData(data);
    mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.oms} | {strings.add_equipment_feature_group}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.oms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routes.oms.equipmentFeaturesGroup.list}>
            {strings.equipment_features_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {strings.add_equipment_feature_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.add_equipment_feature_group}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              <EquipmentFeaturesGroupForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={isLoading}>
                  {strings.add}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddEquipmentFeaturesGroup;
