import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import KeyLogAPI from 'api/keyLogs';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ImageComponent from 'components/ImageComponent';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  V_OFFICE_URL,
} from 'constants/common';
import routes from 'constants/routes';
import KeyStatus from 'pages/dashboard/Key/KeyStatus';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import { DateFormat, utcToLocal } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

const KeyDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const keyDetailsAPI = new KeyAPI();
  const keyLogAPI = new KeyLogAPI();
  const { search } = useLocation();

  const { data: keyDetails, isLoading: keyDetailLoading } = useQuery(
    [`get-key-details-from-key-id`, id],
    async () => {
      return await keyDetailsAPI.get(id).then((res) => res.data.data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const keyLogList = useQuery(
    [
      `keyLogList`,
      keyDetails?.id,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyId: keyDetails?.id,
      };
      return await keyLogAPI.list(queryParams).then((res) => res.data);
    },
    { enabled: !!keyDetails?.id, refetchOnWindowFocus: false, cacheTime: 0 }
  );

  const { data }: any = keyLogList;
  const { data: keyLogLists = [] }: any = { ...data };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  if (keyDetailLoading || keyLogList.isLoading) {
    return <CenterSpinner />;
  }

  const imageUrl = `${V_OFFICE_URL}/image/s/${keyDetails?.apartment?.images?.id}`;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>KMS | {keyDetails.rfid}</title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.key.keyList.list + search}>
              {strings.key_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{keyDetails?.rfid}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {keyDetails?.apartment?.name}
          </Heading>
          <RouterLink
            to={`${routes.key.keyList.edit.replace(
              ':id',
              id.toString() + search
            )}`}>
            <Button colorScheme="primary" size="sm" type="button">
              {strings.edit}
            </Button>
          </RouterLink>
        </Flex>

        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
              'repeat(5, 1fr)',
              'repeat(8, 1fr)',
            ]}
            w="100%">
            <GridItem rowSpan={[1, 1, 4, 3, 3]} colSpan={2}>
              <ImageComponent
                width="1800"
                src={imageUrl}
                alt={keyDetails?.apartment?.name ?? '-'}
              />
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.key_id}</FormLabel>
                <Box sx={infoStyles}>{keyDetails?.rfid}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.registered_date}
                </FormLabel>
                <Box sx={infoStyles}>{DateFormat(keyDetails?.createdAt)}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.object}</FormLabel>
                <Box sx={infoStyles}>{keyDetails?.apartment?.name}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.registered_by}</FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.createUser?.fullName ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.booking_number}</FormLabel>
                <Box sx={infoStyles}>
                  {(keyLogLists?.length > 0 &&
                    keyLogLists[0].reservation?.bookingNumber) ??
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.customer}</FormLabel>
                <Box sx={infoStyles}>
                  {(keyLogLists?.length > 0 &&
                    keyLogLists[0].customer?.fullName) ??
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.status}</FormLabel>
                <Box sx={infoStyles}>
                  <KeyStatus status={keyDetails?.status} />
                </Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 2, 4, 2, 5]}>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                <Box sx={infoStyles}>{keyDetails?.description}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Flex justify="space-between">
          <Heading size="sm" textTransform="capitalize">
            {strings.key_history}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.date_time}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.external_company}</Th>
                  <Th>{strings.notes}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!keyLogList.isLoading &&
                  keyLogLists?.map((keyLogItem: any, index: number) => {
                    return (
                      <Tr key={index}>
                        <Td>{startingSN + index}</Td>
                        <Td>
                          {utcToLocal(keyLogItem.updatedAt).replace(' ', ', ')}
                        </Td>
                        <Td d="flex">
                          <KeyStatus status={keyLogItem.statusTo} />
                        </Td>
                        <Td>{keyLogItem.customer?.fullName || '-'}</Td>
                        <Td>{keyLogItem.externalCompany?.name || '-'}</Td>
                        <Td>{keyLogItem.notes || '-'}</Td>
                      </Tr>
                    );
                  })}
                {keyLogList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        <Pagination
          dataList={keyLogList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default KeyDetails;
