import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import RMSObjectsResource from 'api/rms-objects';
import { AxiosError, AxiosResponse } from 'axios';
import ImageComponent from 'components/ImageComponent';
import { strings } from 'config/localization';
import { ImageSchema } from 'constants/schemas/objektSchema';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

const getFileNameExtention = (url: string) => {
  const parts = url.split('.');
  const extension = '.' + parts.pop();
  const name = parts.join('.');
  return { name, extension };
};

export type ObjektPictureEditFormValue = {
  filename: string;
  caption?: string;
  tags?: string[];
  alt_tag?: string;
};

type Props = {
  picture: ImageSchema;
  isDisabled?: boolean;
};

function ObjektPictureEdit({ picture, isDisabled }: Props) {
  const { file_name, custom_properties, name, url, id } = picture;
  const { alt_tag, caption, tags } = custom_properties;

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: id,
    data: picture,
    disabled: isDisabled,
  });

  const objectAPI = new RMSObjectsResource();

  const queryClient = useQueryClient();

  const params = useParams<{ id: string }>();
  const apartmentId = Number(params.id);

  const {
    isOpen: isEditModalOpen,
    onClose: closeEditModal,
    onOpen: openEditModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onClose: closeDeleteModal,
    onOpen: openDeleteModal,
  } = useDisclosure();

  const toast = useToast();

  const { extension } = getFileNameExtention(file_name);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ObjektPictureEditFormValue>({
    defaultValues: {
      filename: name,
      caption,
      tags,
      alt_tag,
    },
  });

  const [updatedFileName, setUpdatedFileName] = useState(name);

  const updateImageDetail = (
    data: ObjektPictureEditFormValue
  ): Promise<AxiosResponse<any>> => {
    return objectAPI.updateObjectImageDetail({
      apartmentId,
      imageId: id,
      data,
    });
  };

  const deleteMutation = useMutation({
    mutationFn: (imageId: number) => objectAPI.deleteObjectImages(imageId),
    onSuccess: () => {
      toast({
        title: strings.successfully_deleted_image,
        status: 'success',
        isClosable: true,
      });
      closeDeleteModal();
      queryClient.invalidateQueries(`apartmentDetails-${apartmentId}`);
    },
    onError: () => {
      toast({
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const updateMutation = useMutation<
    AxiosResponse<any>,
    AxiosError,
    ObjektPictureEditFormValue
  >(updateImageDetail, {
    onSuccess: (response) => {
      toast({
        title: strings.successfully_updated_image_details,
        status: 'success',
        isClosable: true,
      });
      const updatedData = response.data.data;
      const newFileName = updatedData.file_name;
      setUpdatedFileName(newFileName);
      closeEditModal();
      queryClient.invalidateQueries(`apartmentDetails-${apartmentId}`);
    },
    onError: (error: AxiosError) => {
      const message =
        (error.response?.data && error.response.data.message) ?? '';
      if (message === 'New filename already exists') {
        setError('filename', {
          shouldFocus: true,
          message: strings.filename_already_exists,
        });
        return;
      }
      toast({
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const onSubmit: SubmitHandler<ObjektPictureEditFormValue> = (data) => {
    const { filename } = data;
    const fileNameWithExtension = `${filename}${extension}`;
    updateMutation.mutate({ ...data, filename: fileNameWithExtension });
  };

  return (
    <>
      <Flex
        key={id}
        title={picture.name}
        flexDir="column"
        gridGap="1"
        alignItems="center"
        height="100%"
        justifyContent="space-between"
        position="relative"
        ref={setNodeRef}
        transform={CSS.Transform.toString(transform)}
        transition={transition}
        withOpacity={isDragging}
        zIndex={isDragging ? 1 : 0}
        opacity={isDragging ? 0.5 : 1}
        cursor={isDisabled ? 'wait' : 'grab'}
        {...attributes}
        {...listeners}>
        <Box position="relative" width="100%">
          <ImageComponent
            src={picture.url}
            key={picture.id}
            alt={picture.name}
            maxH="200"
            width="1800"
            objectFit="cover"
            title={picture.name}
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundColor="rgba(0,0,0,0.6)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            opacity="0"
            transition="opacity 0.2s"
            _hover={{ opacity: 1 }}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit}
              color="blue.300"
              fontSize={32}
              onClick={openEditModal}
            />
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_user}
              color="red.300"
              fontSize={32}
              onClick={openDeleteModal}
            />
          </Box>
        </Box>
        <Text
          color="white"
          backgroundColor="primary.400"
          w="full"
          py={2}
          fontSize={14}
          textAlign="center">
          {updatedFileName}
        </Text>
      </Flex>

      {isEditModalOpen && (
        <Modal
          isCentered
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          size={'3xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.update_image_detail}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid
                gridTemplateColumns={{
                  sm: 'repeat(1,1fr)',
                  lg: 'repeat(2,1fr)',
                }}
                gap="8">
                <ImageComponent
                  src={url}
                  alt={alt_tag}
                  width="1800"
                  height="100%"
                />
                <>
                  <Flex flexDir="column" gridGap="4">
                    <FormControl isRequired isInvalid={!!errors?.filename}>
                      <FormLabel>{strings.file}</FormLabel>

                      <InputGroup size="sm">
                        <Input
                          type="text"
                          autoFocus
                          {...register('filename', {
                            required: strings.required,
                          })}
                        />
                        <InputRightAddon>{extension}</InputRightAddon>
                      </InputGroup>

                      {!!errors?.filename && (
                        <FormErrorMessage>
                          {errors.filename.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.altTag}</FormLabel>
                      <Input type="text" {...register('alt_tag')} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{strings.imageCaption}</FormLabel>
                      <Input type="text" {...register('caption')} />
                    </FormControl>
                  </Flex>
                </>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="primary"
                mr={3}
                type="submit"
                disabled={updateMutation.isLoading}
                isLoading={updateMutation.isLoading}
                onClick={handleSubmit(onSubmit)}>
                {strings.update}
              </Button>
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={closeEditModal}>
                {strings.cancel}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal isCentered isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_image}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.are_you_sure_you_cannot_undo_this_action}
            </ModalBody>

            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="red"
                mr={3}
                type="submit"
                disabled={deleteMutation.isLoading}
                isLoading={deleteMutation.isLoading}
                onClick={() => deleteMutation.mutate(id)}>
                {strings.delete}
              </Button>
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={closeDeleteModal}>
                {strings.cancel}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default ObjektPictureEdit;
