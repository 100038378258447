import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Stack, Text } from '@chakra-ui/layout';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Switch } from '@chakra-ui/switch';
import { Td, Tr } from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import EquipmentFeatureGroupResource from 'api/equipment_feature_group';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  EquipmentFeaturesFormType,
  EquipmentFeaturesGroup,
} from 'constants/schema';
import React from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
type Props = {
  equipmentFeature: EquipmentFeaturesGroup;
  startingSN: number;
  queryKey?: readonly [
    'equipment-group',
    'list',
    string | undefined,
    number,
    number
  ];
};

const EquipmentFeaturesGroupListItem: React.FC<Props> = ({
  equipmentFeature,
  startingSN,
  queryKey,
}) => {
  const toast = useToast();
  const equipmentFeatureAPI = new EquipmentFeatureGroupResource();
  const queryClient = useQueryClient();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { mutate: deleteMutation, isLoading: isDeleteMutationLoading } =
    useMutation({
      mutationFn: () => equipmentFeatureAPI.destroy(equipmentFeature.id),
      onSuccess: () => {
        toast({
          status: 'success',
          title: strings.successfully_deleted_equipment_feature_group,
        });
        queryClient.invalidateQueries({ queryKey: queryKey });
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.failed_to_delete_equipment_feature_group,
        });
      },
    });

  const { mutate: updateMutation, isLoading: isUpdateMutationLoading } =
    useMutation({
      mutationFn: (payload: Partial<EquipmentFeaturesFormType>) =>
        equipmentFeatureAPI.update(equipmentFeature.id, {
          ...payload,
          _method: 'PUT',
        }),
      onSuccess: () => {
        toast({
          status: 'success',
          title: strings.successfully_updated_equipment_feature_detail,
        });
        queryClient.invalidateQueries({ queryKey: queryKey });
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.failed_to_update_equipment_feature_detail,
        });
      },
    });

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const data = { [name]: checked ? 1 : 0 };
    updateMutation(data);
  };

  const onDeleteConfirm = () => {
    deleteMutation();
  };

  return (
    <Tr>
      <Td>{startingSN}</Td>
      <Td>
        <Link
          to={routes.oms.equipmentFeaturesGroup.details.replace(
            ':id',
            equipmentFeature.id.toString()
          )}>
          <Stack direction="row" alignItems="center">
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {equipmentFeature.name}
            </Text>
          </Stack>
        </Link>
      </Td>
      <Td>
        <Stack position="relative">
          <Switch
            size="md"
            name="status"
            colorScheme="green"
            isChecked={!!equipmentFeature.status}
            isDisabled={isUpdateMutationLoading}
            onChange={onStatusChange}
          />
        </Stack>
      </Td>
      <Td>
        <Link
          to={routes.oms.equipmentFeaturesGroup.details.replace(
            ':id',
            equipmentFeature.id.toString()
          )}>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </Link>
        <Link
          to={routes.oms.equipmentFeaturesGroup.edit.replace(
            ':id',
            equipmentFeature.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </Link>
        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            isDisabled={isDeleteMutationLoading}
            onClick={() => onOpen()}
          />
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_equipment_feature_group}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.are_you_sure_you_cannot_undo_this_action}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="primary"
                mr={3}
                onClick={onDeleteConfirm}
                isLoading={isDeleteMutationLoading}
                isDisabled={isDeleteMutationLoading}>
                {strings.delete}
              </Button>
              <Button colorScheme="primary" variant="outline" onClick={onClose}>
                {strings.cancel}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default EquipmentFeaturesGroupListItem;
