import { Bread, ObjectDashboard } from 'assets/icons';
import {
  AERIAL_MAP_URL,
  DASHBOARD_URL,
  THEME_AERIAL_MAP,
  THEME_BMS,
  THEME_BS,
  THEME_CHAT,
  THEME_CONTACT_CENTER,
  THEME_CRM,
  THEME_DMS,
  THEME_FAQ,
  THEME_FEEDBACK,
  THEME_KEY,
  THEME_MDM,
  THEME_MMS,
  THEME_NEWS,
  THEME_OBJECT_DASHBOARD,
  THEME_OMS,
  THEME_PMS,
  THEME_PNS,
  THEME_RESTAURANT,
  THEME_SMS,
  THEME_THIRD_PARTY_PARTNER,
  THEME_TICKET,
  THEME_USER,
  TRAVELYTICS_URL,
} from 'constants/common';
import routes from 'constants/routes';
import { ServiceListSchema } from 'constants/schema';
import {
  BiBell,
  BiBookBookmark,
  BiBuildingHouse,
  BiChat,
  BiCoffee,
  BiCoinStack,
  BiCreditCard,
  BiFile,
  BiHelpCircle,
  BiKey,
  BiMailSend,
  BiMapAlt,
  BiMessageRoundedEdit,
  BiPurchaseTag,
  BiRestaurant,
  BiSupport,
  BiTachometer,
  BiUser,
  BiUserPin,
  BiWindowAlt,
} from 'react-icons/bi';
import { BsFilePerson, BsGraphUpArrow } from 'react-icons/bs';

export const serviceListData: ServiceListSchema[] = [
  {
    id: 1,
    serviceName: 'object-dashboard',
    hideForLabel: 'object-dashboard',
    tooltipLabel: 'object_dashboard',
    theme: THEME_OBJECT_DASHBOARD,
    themeColor: 'purple3',
    icon: ObjectDashboard,
    splashLabel: 'object_dashboard',
    bg: 'purple3.400',
    boxShadow: '6px 6px rgba(170,129,222,0.2)',
    hover: {
      bg: 'purple3.500',
      boxShadow: '6px 6px rgba(170,129,222,0.4)',
    },
    rootLink: '/object-dashboard',
    routes: [
      [routes.objectDashboard.dashboard.contactList, 'read:object-dashboard'],
      [routes.objectDashboard.crossSelling.list, 'manage:cross-selling'],
    ],
    subMenusComp: 'ObjectDashboardSubMenu',
  },
  {
    id: 2,
    serviceName: 'user',
    hideForLabel: 'user-service',
    tooltipLabel: 'user_management_system',
    theme: THEME_USER,
    themeColor: 'blue',
    icon: BiUser,
    splashLabel: 'user_management',
    bg: 'blue.400',
    boxShadow: '6px 6px rgba(167,139,250,0.4)',
    hover: {
      bg: 'blue.500',
      boxShadow: '6px 6px rgba(66,153,225,0.4)',
    },
    rootLink: '/users',
    routes: [
      [routes.users.list, 'read:user'],
      [routes.users.roles.list, 'read:user'],
      [routes.users.externalCompanies.list, 'manage:external-companies'],
      [routes.users.loginLog.list, 'read:login-log'],
    ],
    subMenusComp: 'UserSubMenuList',
  },
  {
    id: 3,
    serviceName: 'chat',
    hideForLabel: 'chat-service',
    tooltipLabel: 'chat_system',
    theme: THEME_CHAT,
    themeColor: 'green',
    icon: BiChat,
    splashLabel: 'chat',
    bg: 'green.400',
    boxShadow: '6px 6px rgba(52,211,153,0.2)',
    hover: {
      bg: 'green.500',
      boxShadow: '6px 6px  rgba(52,211,153,0.4)',
    },
    rootLink: '/chat',
    routes: [
      [routes.chat.default, 'read:chat'],
      [routes.chat.closed.list, 'read:chat'],
      [routes.chat.archived.list, 'read:chat'],
      [routes.chat.metrics, 'read:chat-metrics'],
    ],
    subMenusComp: 'ChatSubMenuList',
  },
  {
    id: 4,
    serviceName: 'ticket',
    hideForLabel: 'ticket-service',
    tooltipLabel: 'ticket_system',
    theme: THEME_TICKET,
    themeColor: 'purple',
    icon: BiPurchaseTag,
    splashLabel: 'ticket',
    bg: 'purple.400',
    boxShadow: '6px 6px rgba(167,139,250,0.2)',
    hover: {
      bg: 'purple.500',
      boxShadow: '6px 6px rgba(167,139,250,0.4)',
    },
    rootLink: '/ticket',

    routes: [
      [routes.ticket.task.board, 'read:ticket'],
      [routes.ticket.list.default, 'read:ticket'],
      [routes.ticket.objectsInfo, 'read:ticket'],
    ],
    subMenusComp: 'TicketSubMenuList',
  },
  {
    id: 5,
    serviceName: 'crm',
    hideForLabel: 'crm-service',
    tooltipLabel: 'customer_relation_management',
    theme: THEME_CRM,
    themeColor: 'red',
    icon: BiUserPin,
    splashLabel: 'crm',
    bg: 'red.400',
    boxShadow: '6px 6px rgba(254,101,101,0.2)',
    hover: {
      bg: 'red.500',
      boxShadow: '6px 6px rgba(254,101,101,0.4)',
    },
    rootLink: '/crm',

    routes: [
      [routes.crm.contact.list, 'read:crm'],
      [routes.crm.merge.list, 'merge:contacts'],
    ],
    subMenusComp: 'CrmSubMenuList',
  },
  {
    id: 6,
    serviceName: 'contactCenter',
    hideForLabel: 'contact-center-service',
    tooltipLabel: 'contact_center',
    theme: THEME_CONTACT_CENTER,
    themeColor: 'cyan',
    icon: BiSupport,
    splashLabel: 'contact_center',
    bg: 'cyan.400',
    boxShadow: '6px 6px rgba(8,197,234,0.2)',
    hover: {
      bg: 'cyan.500',
      boxShadow: '6px 6px rgba(8,197,234,0.4)',
    },
    rootLink: '/contact-center',
    routes: [
      [routes.contactCenter.members.list, 'read:contact-center'],
      [routes.contactCenter.totalCalls.list, 'read:contact-center'],
    ],
    subMenusComp: 'ContactCenterSubMenuList',
  },
  {
    id: 7,
    serviceName: 'feedback',
    hideForLabel: 'feedback-service',
    tooltipLabel: 'feedback_system',
    theme: THEME_FEEDBACK,
    themeColor: 'teal',
    icon: BiMessageRoundedEdit,
    splashLabel: 'feedback',
    bg: 'teal.400',
    boxShadow: '6px 6px rgba(77,186,180,0.2)',
    hover: {
      bg: 'teal.500',
      boxShadow: '6px 6px rgba(77,186,180,0.4)',
    },
    rootLink: '/feedback',
    routes: [
      [routes.feedback.feedbacks.list, 'read:feedback'],
      [routes.feedback.dashboard, 'read:feedback'],
      [routes.feedback.analysis, 'read:feedback'],
      [routes.feedback.mails.list, 'read:feedback'],
      [routes.feedback.dashboard, 'manage:feedback'],
      [routes.feedback.setting.list, 'manage:feedback'],
    ],
    subMenusComp: 'FeedbackSubMenuList',
  },
  {
    id: 8,
    serviceName: 'mms',
    hideForLabel: 'mms-service',
    tooltipLabel: 'merchandise_management_system',
    theme: THEME_MMS,
    themeColor: 'orange',
    icon: BiWindowAlt,
    splashLabel: 'mms',
    bg: 'orange.400',
    boxShadow: '6px 6px rgba(246,173,85,0.2)',
    hover: {
      bg: 'orange.500',
      boxShadow: '6px 6px rgba(246,173,85,0.4)',
    },
    rootLink: '/mms',
    routes: [
      [routes.mms.transferItems, 'read:mms'],
      [routes.mms.inventory.list, 'read:mms'],
      [routes.mms.articles.list, 'read:mms'],
      [routes.mms.objects.list, 'read:mms'],
      [routes.mms.warehouse.list, 'read:mms'],
      [routes.mms.types.list, 'read:mms'],
      [routes.mms.units.list, 'read:mms'],
      [routes.mms.transferLogs, 'read:mms'],
      [routes.mms.myInventory.list, 'read:mms'],
      [routes.mms.myLogs.list, 'read:mms'],
      [routes.mms.qrCode.list, 'read:user'],
      [routes.mms.stocktaking.stocktakingMode, 'manage:stocktaking'],
    ],
    subMenusComp: 'MmsSubMenuList',
  },
  {
    id: 9,
    serviceName: 'mdm',
    hideForLabel: 'mdm-service',
    tooltipLabel: 'master_data_magnagement',
    theme: THEME_MDM,
    themeColor: 'malibu',
    icon: BiCoinStack,
    splashLabel: 'mdm',
    bg: 'malibu.400',
    boxShadow: '6px 6px rgba(127,144,253,0.2)',
    hover: {
      bg: 'malibu.500',
      boxShadow: '6px 6px rgba(127,144,253,0.4)',
    },
    rootLink: '/mdm',
    routes: [
      [routes.mdm.uploads.list, 'read:mdm'],
      [routes.mdm.contacts.list, 'read:mdm'],
      [routes.mdm.bookings.list, 'read:mdm'],
      [routes.mdm.invoices.list, 'read:mdm'],
      [routes.mdm.imports.list, 'read:mdm'],
      [routes.mdm.newsletter.list, 'read:mdm'],
    ],
    subMenusComp: 'MdmSubMenuList',
  },
  {
    id: 10,
    serviceName: 'dms',
    hideForLabel: 'dms-service',
    tooltipLabel: 'document_management_system',
    theme: THEME_DMS,
    themeColor: 'blue2',
    icon: BiFile,
    splashLabel: 'dms',
    bg: 'blue2.400',
    boxShadow: '6px 6px rgba(28,170,200,0.2)',
    hover: {
      bg: 'blue2.500',
      boxShadow: '6px 6px rgba(28,170,200,0.4)',
    },
    rootLink: '/dms',
    routes: [
      [routes.dms.generalList.list, 'read:dms'],
      [routes.dms.customerDocuments.list, 'read:customer-dms'],
      [routes.dms.ownerDocuments.list, 'read:owner-dms'],
      [routes.dms.objectDocuments.list, 'read:object-dms'],
      [routes.dms.userDocuments.list, 'read:user-dms'],
    ],
    subMenusComp: 'DMSSubMenuList',
  },
  {
    id: 11,
    serviceName: 'oms',
    hideForLabel: 'oms-service',
    tooltipLabel: 'object_management_system',
    theme: THEME_OMS,
    themeColor: 'purple2',
    icon: BiBuildingHouse,
    splashLabel: 'objects',
    bg: 'purple2.400',
    boxShadow: '6px 6px rgba(170,129,222,0.2)',
    hover: {
      bg: 'purple2.500',
      boxShadow: '6px 6px rgba(170,129,222,0.4)',
    },
    rootLink: '/oms',
    routes: [
      [routes.oms.objects.list, 'read:oms'],
      [routes.oms.equipmentFeaturesGroup.list, 'read:oms'],
      [routes.oms.equipmentFeatures.list, 'read:oms'],
    ],
    subMenusComp: 'OmsSubMenuList',
  },
  {
    id: 12,
    serviceName: 'sms',
    hideForLabel: 'sms-service',
    tooltipLabel: 'service_management_system',
    theme: THEME_SMS,
    themeColor: 'brown',
    icon: BiCoffee,
    splashLabel: 'sms',
    bg: 'brown.400',
    boxShadow: '6px 6px rgba(145,143,119,0.2)',
    hover: {
      bg: 'brown.500',
      boxShadow: '6px 6px rgba(145,143,119,0.4)',
    },
    rootLink: '/sms',
    routes: [
      [routes.sms.services.list, 'read:sms'],
      [routes.sms.serviceGroups.list, 'read:sms'],
      [routes.sms.meerSylt.list, 'read:sms'],
      [routes.sms.rabatt.list, 'read:sms'],
    ],
    subMenusComp: 'SMSSubMenuList',
  },
  {
    id: 13,
    serviceName: 'bms',
    hideForLabel: 'bms-service',
    tooltipLabel: 'booking_management_system',
    theme: THEME_BMS,
    themeColor: 'darkbrown',
    icon: BiBookBookmark,
    splashLabel: 'bms',
    bg: 'darkbrown.400',
    boxShadow: '6px 6px rgba(169,158,102,0.2)',
    hover: {
      bg: 'darkbrown.500',
      boxShadow: '6px 6px rgba(169,158,102,0.4)',
    },
    rootLink: '/bms',
    routes: [
      [routes.bms.reservation.list, 'read:reservations'],
      [routes.bms.reservationRequest.list, 'read:reservations'],
      [routes.bms.callback.list, 'read:callbacks'],
      [routes.bms.booking.list, 'read:bookings'],
      [routes.bms.ferryBooking.list, 'read:bookings'],
      [routes.bms.checkInOut.list, 'read:booking-terminals'],
      [routes.bms.marketingEmail.list, 'read:bookings'],
      [routes.bms.marketingEmailSettings.list, 'read:bookings'],
      [routes.bms.dashboard.list, 'read:bookings'],
    ],
    subMenusComp: 'BMSSubMenuList',
  },
  {
    id: 14,
    serviceName: 'bs',
    hideForLabel: 'bs-service',
    tooltipLabel: 'bread_system',
    theme: THEME_BS,
    themeColor: 'burlywood',
    icon: Bread, //Bread, color:white required
    splashLabel: 'bs',
    bg: 'burlywood.400',
    boxShadow: '6px 6px rgba(215,172,11,0.2)',
    hover: {
      bg: 'burlywood.500',
      boxShadow: '6px 6px rgba(215,172,11,0.4)',
    },
    rootLink: '/bs',
    routes: [
      [routes.bs.breadBooking.list, 'read:bread-service'],
      [routes.bs.breadBookingBilling.list, 'read:bread-service'],
      [routes.bs.breadDailyView.list, 'read:bread-service'],
      [routes.bs.drivingOrder.list, 'read:bread-driving-order'],
    ],
    subMenusComp: 'BSSubMenuList',
  },
  {
    id: 15,
    serviceName: 'pms',
    hideForLabel: 'pms-service',
    tooltipLabel: 'payment_management_system',
    theme: THEME_PMS,
    themeColor: 'blue3',
    icon: BiCreditCard,
    splashLabel: 'pms',
    bg: 'blue3.400',
    boxShadow: '6px 6px rgba(81,154,195,0.2)',
    hover: {
      bg: 'blue3.500',
      boxShadow: '6px 6px rgba(81,154,195,0.4)',
    },
    rootLink: '/pms',
    routes: [
      [routes.pms.creditCard.list, 'read:pms'],
      [routes.pms.paypal.list, 'read:pms'],
      [routes.pms.invoice.list, 'read:pms'],
      [routes.pms.paymentLinks.list, 'read:pms'],
    ],
    subMenusComp: 'PMSSubMenuList',
  },
  {
    id: 16,
    serviceName: 'pns',
    hideForLabel: 'pns-service',
    tooltipLabel: 'push_notification_system',
    theme: THEME_PNS,
    themeColor: 'pink2',
    icon: BiBell,
    splashLabel: 'pns',
    bg: 'pink2.400',
    boxShadow: '6px 6px rgba(191,70,135,0.2)',
    hover: {
      bg: 'pink2.500',
      boxShadow: '6px 6px rgba(191,70,135,0.4)',
    },
    rootLink: '/pns',
    routes: [[routes.pns.notification.list, 'read:pns']],
    subMenusComp: 'PNSSubMenuList',
  },
  {
    id: 17,
    serviceName: 'faq',
    hideForLabel: 'faq-service',
    tooltipLabel: 'frequently_ask_question',
    theme: THEME_FAQ,
    themeColor: 'green2',
    icon: BiHelpCircle,
    splashLabel: 'faq',
    bg: 'green2.400',
    boxShadow: '6px 6px rgba(191,70,135,0.2)',
    hover: {
      bg: 'green2.500',
      boxShadow: '6px 6px rgba(191,70,135,0.4)',
    },
    rootLink: '/faq',
    routes: [
      [routes.faq.faqList.list, 'read:faq'],
      [routes.faq.internalfaq.list, 'read:internal-faq'],
      [routes.faq.faqGroup.list, 'read:faq-group'],
    ],
    subMenusComp: 'FAQSubMenuList',
  },
  {
    id: 18,
    serviceName: 'newsletter',
    hideForLabel: 'newsletter-service',
    tooltipLabel: 'newsletter',
    theme: THEME_NEWS,
    themeColor: 'yellow',
    icon: BiMailSend,
    splashLabel: 'newsletter',
    bg: 'yellow.400',
    boxShadow: '6px 6px rgba(191,70,135,0.2)',
    hover: {
      bg: 'yellow.500',
      boxShadow: '6px 6px rgba(191,70,135,0.4)',
    },
    rootLink: '/newsletter',
    routes: [
      [routes.newsletter.newsletters.list, 'read:newsletter'],
      [routes.newsletter.draft.list, 'read:newsletter'],
    ],
    subMenusComp: 'NewsletterSubMenuList',
  },
  {
    id: 19,
    serviceName: 'key',
    hideForLabel: 'key-service',
    tooltipLabel: 'key_management_system',
    theme: THEME_KEY,
    themeColor: 'sunrise',
    icon: BiKey,
    splashLabel: 'key',
    bg: 'sunrise.400',
    boxShadow: '6px 6px rgba(234,125,97,0.2)',
    hover: {
      bg: 'sunrise.500',
      boxShadow: '6px 6px rgba(234,125,97,0.4)',
    },
    rootLink: '/key',
    routes: [
      [routes.key.KeyLogs.list, 'read:keylog'],
      [routes.key.keyList.list, 'read:keylist'],
    ],
    subMenusComp: 'KeySubMenuList',
  },
  {
    id: 20,
    serviceName: 'aerialMap',
    hideForLabel: 'aerial-map-service',
    tooltipLabel: 'aerial_map',
    theme: THEME_AERIAL_MAP,
    themeColor: 'greygreen',
    icon: BiMapAlt,
    splashLabel: 'aerial_map',
    bg: 'greygreen.400',
    boxShadow: '6px 6px rgba(95, 150, 86,0.2)',
    hover: {
      bg: 'greygreen.500',
      boxShadow: '6px 6px rgba(95, 150, 86,0.4)',
    },
    rootLink: '/aerial-map',
    routes: [[AERIAL_MAP_URL, 'read:aerial-map']],
    subMenusComp: 'null',
  },
  {
    id: 21,
    serviceName: 'dashboard',
    hideForLabel: 'dashboard',
    tooltipLabel: 'dashboard',
    theme: '',
    themeColor: 'pink',
    icon: BiTachometer,
    splashLabel: 'dashboard',
    bg: 'pink.400',
    boxShadow: '6px 6px rgba(244,113,182,0.2)',
    hover: {
      bg: 'pink.500',
      boxShadow: '6px 6px rgba(244,113,182,0.4)',
    },
    rootLink: '/dashboard',
    routes: [[DASHBOARD_URL, 'read:dashboard']],
    subMenusComp: 'null',
  },
  {
    id: 22,
    serviceName: 'travelytics',
    hideForLabel: 'travelytics',
    tooltipLabel: 'travelytics',
    theme: '',
    themeColor: 'darkbrown2',
    icon: BsGraphUpArrow,
    splashLabel: 'travelytics',
    bg: 'darkbrown.600',
    boxShadow: '6px 6px rgb(174, 141, 123)',
    hover: {
      bg: 'darkbrown.700',
      boxShadow: '6px 6px rgb(174, 141, 123)',
    },
    rootLink: '/travelytics',
    routes: [[TRAVELYTICS_URL, 'read:travelytics']],
    subMenusComp: 'null',
  },
  {
    id: 23,
    serviceName: 'third-party-partner',
    hideForLabel: 'third-party-partner',
    tooltipLabel: 'third_party_partner',
    theme: THEME_THIRD_PARTY_PARTNER,
    themeColor: 'partner',
    icon: BsFilePerson,
    splashLabel: 'third_party_partner',
    bg: 'partner.400',
    boxShadow: '6px 6px rgba(170,129,222,0.2)',
    hover: {
      bg: 'partner.500',
      boxShadow: '6px 6px rgba(170,129,222,0.4)',
    },
    rootLink: '/member-level',
    routes: [
      [
        routes.thirdPartyPartner.membershipLevel.list,
        'manage:third-party-partner',
      ],
      [
        routes.thirdPartyPartner.thirdPartyPartner.list,
        'manage:third-party-partner',
      ],
    ],

    subMenusComp: 'ThirdPartyPartnerSubMenuList',
  },
  {
    id: 24,
    serviceName: 'restaurant',
    hideForLabel: 'restaurant',
    tooltipLabel: 'restaurant',
    theme: THEME_RESTAURANT,
    themeColor: 'pink3',
    icon: BiRestaurant,
    splashLabel: 'restaurant',
    bg: 'pink3.500',
    boxShadow: '6px 6px rgb(174, 141, 123)',
    hover: {
      bg: 'pink3.600',
      boxShadow: '6px 6px rgb(174, 141, 123)',
    },
    rootLink: '/restaurant',
    routes: [
      [routes.restaurant.restaurant.list, 'manage:restaurant'],
      [routes.restaurant.restaurantReservation.list, 'manage:restaurant'],
    ],
    subMenusComp: 'RestaurantSubMenuList',
  },
];
