import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import {
  deletedStyles,
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { Merge } from 'assets/icons';
import FallBackImage from 'assets/images/chat-not-available.png';
import FileDetails from 'components/common/FileDetails';
import { getTag } from 'components/common/PriorityTag';
import CustomTagTitle from 'components/CustomTagTitle';
import ImageComponent from 'components/ImageComponent';
import StatusForm from 'components/ticket/StatusForm';
import { strings } from 'config/localization';
import { costRecipientMap } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import { TicketInfoSchema } from 'constants/schemas/TicketSchema';
import * as FileSaver from 'file-saver';
import useCheckPermission from 'hooks/useCheckPermission';
import useImageSlide from 'hooks/useImageSlider';
import React, { useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiCloudDownload } from 'react-icons/bi';
import { getCustomerOrFail } from 'utils';
import { DateFormat, DateTimeFormatToUTC } from 'utils/DateFormat';

const hoverImageStyle = {
  _hover: {
    position: 'absolute',
    top: '0',
    rigt: '0',
    background: 'gray.300',
    opacity: '50%',
    cursor: 'pointer',
    width: '100%',
  },
};
const btnLeft = {
  position: 'absolute',
  top: '50%',
  left: '0%',
};

const btnRight = {
  position: 'absolute',
  top: '50%',
  right: '0%',
};

const sliderImage = {
  width: '100%',
  height: '450px',
};

interface Props {
  ticket: TicketInfoSchema;
  path: string;
}

const TicketInfo: React.FC<Props> = (props) => {
  const { ticket, path } = props;

  const [openImageModal, setOpenImageModal] = useState<boolean>(false);

  const imageFiles = ticket?.ticket_attachments.filter(
    (file: any) => file.mime_type.split('/')[0].toLowerCase() === 'image'
  );
  const otherFiles = ticket?.ticket_attachments.filter(
    (file: any) => file.mime_type.split('/')[0].toLowerCase() !== 'image' // all files except images
  );

  const { imageIndex, onIncrement, onDecrement, setImageIndex } = useImageSlide(
    imageFiles?.length
  );

  const checkEditTicketPermission = useCheckPermission(
    [PermissionRequest['edit:ticket']],
    path.replace(':id', ticket?.id.toString())
  );

  const handleOpenModal = (index: number) => {
    setImageIndex(index);
    setOpenImageModal(true);
  };

  const handleCloseModal = () => {
    setOpenImageModal(false);
  };

  const downloadFile = (fileUrl: string) => {
    FileSaver.saveAs(fileUrl, fileUrl);
  };

  const getCostRecipientValue = (): any => {
    if (!ticket?.cost_recipient) return '-';
    return costRecipientMap.get(ticket?.cost_recipient);
  };

  const { isCustomerDeleted, customerName } = getCustomerOrFail(
    ticket.customer_id,
    ticket.customer
  );
  const { responsible_person, substitute_responsible_person } =
    ticket?.apartment ?? {};

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.ticket_details}
        </Heading>
        <Stack direction="row" spacing="6">
          <StatusForm ticketId={ticket?.id} status={ticket?.status} />
          <Button
            size="sm"
            colorScheme="primary"
            onClick={checkEditTicketPermission}>
            {strings.edit_ticket}
          </Button>
        </Stack>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.ticket_name}</FormLabel>
              <Box sx={infoStyles}>{ticket?.title}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.assignee}</FormLabel>
              <Box sx={infoStyles}>
                <CustomTagTitle
                  type="text"
                  status={ticket?.assigned_user?.status}
                  name={ticket?.assigned_user?.name || '-'}
                />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.object}</FormLabel>
              <Box sx={infoStyles}>{ticket?.apartment?.name || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.house_owner}</FormLabel>
              <Box sx={infoStyles}>
                {ticket?.house_owner_customer?.name || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.cost_recipient}</FormLabel>
              <Box sx={infoStyles}>
                {strings.getString(getCostRecipientValue()) || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.due_date}</FormLabel>
              <Box sx={infoStyles}>{DateFormat(ticket?.due_date)}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.appointment}</FormLabel>
              <Box sx={infoStyles}>
                {DateTimeFormatToUTC(ticket?.appointment_date) || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer}</FormLabel>
              <Flex>
                <Box sx={isCustomerDeleted ? deletedStyles : infoStyles}>
                  {customerName || '-'}
                </Box>
                {ticket?.customer?.is_intermixed && (
                  <Box w="6" h="6" ml="2" color="orange.300">
                    <Image src={Merge} />
                  </Box>
                )}
              </Flex>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.external_company}</FormLabel>
              <Box sx={infoStyles}>
                <CustomTagTitle
                  type="text"
                  status={!!ticket?.external_company?.name}
                  name={ticket?.external_company?.name || '-'}
                />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.priority_label}</FormLabel>
              <Box sx={infoStyles}>{getTag(ticket?.priority)}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.person_in_charge}</FormLabel>
              <Box sx={infoStyles}>{responsible_person?.name ?? '-'} </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.substitute_person_in_charge}
              </FormLabel>
              <Box sx={infoStyles}>
                {substitute_responsible_person?.name ?? '-'}
              </Box>
            </FormControl>
          </GridItem>

          <GridItem rowSpan={[2, 4]}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.descriptions}</FormLabel>
              <Box sx={infoStyles}>{ticket?.description || '-'}</Box>
            </FormControl>
          </GridItem>
        </Grid>
        {imageFiles.length >= 0 && (
          <FormLabel sx={labelStyles} mt="1rem !important">
            {strings.images}
          </FormLabel>
        )}
        <Grid gap="2" templateColumns="repeat(auto-fit, 100px)" w="100%">
          {imageFiles?.map((item: any, index: number) => (
            <GridItem key={item.id}>
              <Box position="relative" boxSize="100px">
                <ImageComponent
                  src={item.file_url}
                  boxSize="100px"
                  fallbackSrc={FallBackImage}
                  objectFit="cover"
                  onClick={() => handleOpenModal(index)}
                  alt={item.file_name}
                  sx={hoverImageStyle}
                />
              </Box>
            </GridItem>
          ))}
          <Modal
            isOpen={openImageModal}
            isCentered
            size="4xl"
            onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Tooltip
                  placement="top"
                  hasArrow
                  label={strings.download_image}
                  fontSize="sm">
                  <Button
                    variant="outline"
                    onClick={() =>
                      downloadFile(imageFiles[imageIndex]?.file_url)
                    }>
                    <Icon as={BiCloudDownload} w="6" h="6" />
                  </Button>
                </Tooltip>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack>
                  {imageFiles?.length > 1 && (
                    <Button
                      leftIcon={<BiChevronLeft />}
                      variant="solid"
                      sx={btnLeft}
                      onClick={onDecrement}
                      rounded="full">
                      {strings.previous}
                    </Button>
                  )}
                  <ImageComponent
                    objectFit="contain"
                    width="1600"
                    src={imageFiles[imageIndex]?.file_url}
                    alt={imageFiles[imageIndex]?.file_name}
                    sx={sliderImage}
                  />

                  {imageFiles?.length > 1 && (
                    <Button
                      rightIcon={<BiChevronRight />}
                      variant="solid"
                      sx={btnRight}
                      onClick={onIncrement}
                      rounded="full">
                      {strings.next}
                    </Button>
                  )}
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Grid>
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          {ticket?.ticket_attachments.length > 0 && (
            <GridItem>
              <FormControl>
                <List>
                  {otherFiles?.map((res: any) => {
                    return (
                      <ListItem
                        key={res.id}
                        color="grey.300"
                        _hover={{
                          cursor: 'pointer',
                          color: 'blue.300',
                          textDecoration: 'underline',
                        }}>
                        <a onClick={() => downloadFile(res.file_url)}>
                          <FileDetails
                            fileName={res.file_name}
                            fileSize={res.file_size}
                            mimeType={res.mime_type}
                          />
                        </a>
                      </ListItem>
                    );
                  })}
                </List>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Stack>
    </>
  );
};
export default TicketInfo;
