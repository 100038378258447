import http from 'utils/http';
import Resource from './resource';
class EquipmentFeatureResource extends Resource {
  constructor() {
    super('rms/feature');
  }
  update(id: any, resource: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'post',
      data: resource,
    });
  }
}

export { EquipmentFeatureResource as default };
