import {
  EquipmentFeatureGroupFormType,
  EquipmentFeaturesFormType,
} from 'constants/schema';

export const mapEquipmentGroupFormData = (
  data: EquipmentFeatureGroupFormType
) => {
  const formData = new FormData();
  formData.append('status', !!data.status ? '1' : '0');
  if (data?.icon) formData.append('icon', data.icon);
  formData.append('de[name]', data.titles_de);
  if (data.titles_en) formData.append('en[name]', data.titles_en);
  if (data.titles_pl) formData.append('pl[name]', data.titles_pl);
  if (data.titles_da) formData.append('da[name]', data.titles_da);
  if (data.titles_zh) formData.append('zh[name]', data.titles_zh);
  return formData;
};

export const mapEquipmentFormData = (data: EquipmentFeaturesFormType) => {
  const formData = new FormData();
  formData.append('status', !!data.status ? '1' : '0');
  formData.append('special', !!data.special ? '1' : '0');
  if (data?.icon) formData.append('icon', data.icon);
  formData.append('de[name]', data.titles_de);
  if (data.titles_en) formData.append('en[name]', data.titles_en);
  if (data.titles_pl) formData.append('pl[name]', data.titles_pl);
  if (data.titles_da) formData.append('da[name]', data.titles_da);
  if (data.titles_zh) formData.append('zh[name]', data.titles_zh);
  if (data.feature_group_id)
    formData.append('feature_group_id', data.feature_group_id.toString());
  return formData;
};
